import { Button } from '@/components/ui/button';
import { LoaderCircle, MessageCircle } from 'lucide-react';
import { useTransition } from 'react';
import { toast } from 'sonner';
import { createStartingBonusTransaction } from '../actions';
import { OnboardingContentProps } from '../config';
export function BillingAccounts({
  user,
  moveToNextStep
}: OnboardingContentProps) {
  const [isSubmitting, startSubmition] = useTransition();
  return <>
      {/* TODO */}
      {/* <p className="text-sm text-muted-foreground">
        We&apos;ve created an initial billing account for you.
        You can share billing accounts for centralized billing.
       </p> */}
      <div className="space-y-3">
        {user.user_billing_accounts.map((billingAccount, index) => <div key={index} className="flex items-center justify-between rounded-2xl border border-dashed border-muted-foreground p-2 px-3 shadow">
            <div>
              <h4 className="font-medium">
                {billingAccount.billing_account?.name}
              </h4>
              <p className="text-xs text-muted-foreground">Default Account</p>
            </div>
            <p className="rounded-lg bg-green-500 px-2 py-1 font-mono font-semibold text-white">
              $0.50
            </p>
          </div>)}
      </div>
      {/* TODO */}
      {/* <p className="text-muted-foreground">
        <Info className="me-2 inline size-3.5 shrink-0" />
        You can manage your billing accounts in your account settings.
       </p> */}
      <Button disabled={isSubmitting} className="mt-auto rounded-xl text-lg" onClick={() => startSubmition(async () => {
      const error = await createStartingBonusTransaction(user.id, user.user_billing_accounts[0].billing_account_id);
      if (error) {
        toast.error('Failed to create bonus transaction');
        return;
      }
      moveToNextStep();
    })} data-sentry-element="Button" data-sentry-source-file="billing-accounts.tsx">
        Start chatting!
        {isSubmitting ? <LoaderCircle strokeWidth={1} className="size-4 shrink-0 animate-spin" /> : <MessageCircle className="size-4 shrink-0" />}
      </Button>
    </>;
}
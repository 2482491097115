'use client';

import { useUser } from '@/lib/supabase/browser';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogHeader, AlertDialogTitle } from '../ui/alert-dialog';
import { Button } from '../ui/button';
import { ONBOARDING_STEPS } from './config';
export function OnboardingDialog() {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState<(typeof ONBOARDING_STEPS)[number]>();
  const user = useUser();
  const onboardingSteps = ONBOARDING_STEPS.filter(step => !user?.data?.done_onboard_steps.includes(step.id));
  useEffect(() => {
    if (!user.data) return;
    if (onboardingSteps.length !== 0) {
      setStep(onboardingSteps[0]);
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(onboardingSteps), user.data]);
  const moveToNextStep = useCallback(async (save: boolean = true) => {
    if (!user.data) return;
    if (!step) return;
    setStep(onboardingSteps[onboardingSteps.findIndex(s => s.id === step.id) + 1]);
    setOpen(!!onboardingSteps[onboardingSteps.findIndex(s => s.id === step.id) + 1]);
    if (save) {
      user.update({
        done_onboard_steps: [...user.data.done_onboard_steps, step.id].filter((v, i, a) => a.indexOf(v) === i)
      });
    }
  }, [onboardingSteps, step, user]);
  if (!user.data) {
    return null;
  }
  return <AlertDialog open={open} onOpenChange={setOpen} data-sentry-element="AlertDialog" data-sentry-component="OnboardingDialog" data-sentry-source-file="onboarding-dialog.tsx">
      <AlertDialogContent className="flex max-h-full flex-col justify-start max-sm:size-full max-sm:rounded-none max-sm:border-none" onEscapeKeyDown={e => e.preventDefault()} data-sentry-element="AlertDialogContent" data-sentry-source-file="onboarding-dialog.tsx">
        <AnimatePresence mode="popLayout" data-sentry-element="AnimatePresence" data-sentry-source-file="onboarding-dialog.tsx">
          <motion.div className="flex h-full flex-col gap-3" key={step?.id} initial={{
          opacity: 0,
          x: 32
        }} animate={{
          opacity: 1,
          x: 0
        }} exit={{
          opacity: 0,
          x: -32
        }} data-sentry-element="unknown" data-sentry-source-file="onboarding-dialog.tsx">
            <AlertDialogHeader className="mb-4 text-start max-sm:mt-12" data-sentry-element="AlertDialogHeader" data-sentry-source-file="onboarding-dialog.tsx">
              <AlertDialogTitle className="text-2xl animate-in slide-in-from-bottom-2" data-sentry-element="AlertDialogTitle" data-sentry-source-file="onboarding-dialog.tsx">
                {step?.title}
              </AlertDialogTitle>
              <AlertDialogDescription className="text-lg" data-sentry-element="AlertDialogDescription" data-sentry-source-file="onboarding-dialog.tsx">
                {step?.description}
              </AlertDialogDescription>
            </AlertDialogHeader>
            {step?.content && <step.content user={user.data} updateUser={user.update} moveToNextStep={moveToNextStep} />}
            {!!step?.buttonContent && <Button className="mt-auto rounded-xl text-lg" onClick={() => moveToNextStep()}>
                {step?.buttonContent}
              </Button>}
          </motion.div>
        </AnimatePresence>
      </AlertDialogContent>
    </AlertDialog>;
}
'use client';

import dynamic from 'next/dynamic';
const ShaderGradient = dynamic(() => import('shadergradient').then(mod => mod.ShaderGradient), {
  ssr: false
});
const ShaderGradientCanvas = dynamic(() => import('shadergradient').then(mod => mod.ShaderGradientCanvas), {
  ssr: false
});
export function BackgroundGradient() {
  return <>
      <ShaderGradientCanvas style={{
      pointerEvents: 'none'
    }} data-sentry-element="ShaderGradientCanvas" data-sentry-source-file="shader.tsx">
        <ShaderGradient control="props" type="plane" color1="#d946ef" color2="#3C0C6E" color3="#4f46e5" uStrength={1} uDensity={2} uAmplitude={0} uFrequency={5.5} uSpeed={0.2} uTime={0.2} animate="on" brightness={1.1} grain="on" lightType="3d" cAzimuthAngle={0} cPolarAngle={90} cDistance={5} positionX={0} positionY={0} positionZ={0} rotationX={0} rotationY={0} rotationZ={0} cameraZoom={0.1} envPreset="city" reflection={0.1} shader="defaults" data-sentry-element="ShaderGradient" data-sentry-source-file="shader.tsx" />
      </ShaderGradientCanvas>
    </>;
}
import { createBrowserSupabase } from '@/lib/supabase/browser';
import { fetchModels, SupabaseModel } from '@/lib/supabase/queries';
import { cn } from '@/lib/utils';
import { captureException } from '@sentry/nextjs';
import { ArrowRight, CircleAlert, Info, LoaderCircle } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useEffect, useState, useTransition } from 'react';
import { toast } from 'sonner';
import { ModelPicker } from '../../chat/model-picker';
import { Button } from '../../ui/button';
import { Checkbox } from '../../ui/checkbox';
import { Input } from '../../ui/input';
import { addUserToResend } from '../actions';
import { OnboardingContentProps } from '../config';
export function LetsSetUpYourAccount({
  user,
  updateUser,
  moveToNextStep
}: OnboardingContentProps) {
  const [username, setUsername] = useState(user.username || '');
  const [usernameError, setUsernameError] = useState<string | null>(null);
  const [availableModels, setAvailableModels] = useState<SupabaseModel[]>([]);
  const [model, setModel] = useState<SupabaseModel>();
  const [newsletter, setNewsletter] = useState(true);
  const [isSubmitting, startSubmition] = useTransition();
  const router = useRouter();
  useEffect(() => {
    fetchModels(createBrowserSupabase()).then(({
      data: models,
      error
    }) => {
      if (error) {
        toast.error('Error loading LLM models');
        captureException(error);
        return;
      }
      setAvailableModels(models);
      setModel(user.default_llm?.is_active ? user.default_llm : models.find(m => m.id_tag === 'deepseek-v3'));
    });
  }, [user.default_llm, user.default_llm_id]);
  return <>
      {/* Username */}
      <div className="flex flex-col gap-2">
        <p>What username would you like to use?</p>
        <Input placeholder="Username" className={cn(usernameError && 'border-destructive')} value={username} onChange={e => {
        setUsername(e.target.value);
        if (e.target.value) setUsernameError(null);else setUsernameError('Username is required');
      }} data-sentry-element="Input" data-sentry-source-file="account-setup.tsx" />
        {usernameError && <p className="flex items-center gap-1 text-xs text-destructive">
            <CircleAlert className="size-3 shrink-0" />
            Username is required
          </p>}
      </div>
      {/* Default LLM */}
      <div className="mt-2 flex flex-col gap-2">
        <p>What AI model should we set as your default?</p>
        <ModelPicker availableModels={availableModels} model={model} setModel={m => !!m && setModel(m)} title="Choose your default AI model" data-sentry-element="ModelPicker" data-sentry-source-file="account-setup.tsx" />
        <p className="flex items-center gap-1 text-xs text-muted-foreground">
          <Info className="size-3 shrink-0" data-sentry-element="Info" data-sentry-source-file="account-setup.tsx" />
          New chats will use this model by default.
        </p>
      </div>
      {/* Product newsletter */}
      <div className="items-top flex space-x-2 pt-3">
        <Checkbox id="newsletter" checked={newsletter} onCheckedChange={v => setNewsletter(v === 'indeterminate' ? false : v)} data-sentry-element="Checkbox" data-sentry-source-file="account-setup.tsx" />
        <div className="grid gap-1.5 leading-none">
          <label htmlFor="newsletter" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
            Subscribe to our product newsletter
          </label>
          <p className="text-xs text-muted-foreground">
            We will only send you new features and updates. Max 1 email per
            week. No marketing.
          </p>
        </div>
      </div>
      <Button disabled={isSubmitting} className="mt-auto rounded-xl text-lg" onClick={() => startSubmition(async () => {
      if (!username) {
        setUsernameError('Username is required');
        return;
      }
      let resend_id: string | undefined = undefined;
      if (newsletter && user.email) {
        const {
          data,
          error
        } = await addUserToResend(user.email);
        if (error) {
          captureException(error);
          toast.error('There was an error subscribing you to the newsletter. We will examine this and add you manually later.');
        } else {
          resend_id = data!.id;
        }
      }
      const newUser = await updateUser({
        default_llm_id: model?.id,
        resend_id,
        username
      });
      if (!newUser) return;
      const res = await createBrowserSupabase().from('billing_accounts').update({
        name: `${username}'s billing account`
      }).eq('id', newUser.user_billing_accounts[0].billing_account_id);
      if (res.error) {
        toast.error('Failed to update billing account');
        captureException(res.error);
        return;
      }
      router.refresh();
      moveToNextStep();
    })} data-sentry-element="Button" data-sentry-source-file="account-setup.tsx">
        Continue
        {isSubmitting ? <LoaderCircle strokeWidth={1} className="size-4 shrink-0 animate-spin" /> : <ArrowRight className="size-4 shrink-0" />}
      </Button>
    </>;
}
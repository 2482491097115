import { JSONValue } from 'ai'
import { clsx, type ClassValue } from 'clsx'
import { customAlphabet } from 'nanoid'
import { twMerge } from 'tailwind-merge'
import { SupabaseMessage } from './supabase/queries'
export { v4 as uuid } from 'uuid'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const nanoid = customAlphabet(
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  7,
) // 7-character random string

export async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> {
  const res = await fetch(input, init)

  if (!res.ok) {
    const json = await res.json()
    if (json.error) {
      const error = new Error(json.error) as Error & {
        status: number
      }
      error.status = res.status
      throw error
    } else {
      throw new Error('An unexpected error occurred')
    }
  }

  return res.json()
}

export function formatDate(input: string | number | Date): string {
  const date = new Date(input)
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
}

export function formatDateTime(input: string | number | Date): string {
  const date = new Date(input)
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
}

export const formatDollars = (
  value: number,
  { decimals = 2, fixed = false } = {},
) =>
  `$${fixed ? value.toFixed(decimals) : value.toFixed(decimals).replace(/\.?0+$/, '')}`

export const formatCents = (
  value: number,
  { decimals = 3, fixed = false } = {},
) => {
  const valueFixed = (value * 100).toFixed(
    value < 0.0000000000001 ? 0 : decimals,
  )
  return `${fixed ? valueFixed : valueFixed.replace(/\.?0+$/, '')}¢`
}

export function formatTimeRemaining(seconds: number): string {
  if (seconds < 60) {
    return `${seconds} seconds`
  }
  if (seconds < 3600) {
    const minutes = Math.ceil(seconds / 60)
    return `${minutes} minute${minutes > 1 ? 's' : ''}`
  }
  if (seconds < 86400) {
    const hours = Math.ceil(seconds / 3600)
    return `${hours} hour${hours > 1 ? 's' : ''}`
  }
  const days = Math.ceil(seconds / 86400)
  return `${days} day${days > 1 ? 's' : ''}`
}

export const runAsyncFnWithoutBlocking = (
  fn: (...args: any) => Promise<any>,
) => {
  fn()
}

export const sleep = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms))

export const getStringFromBuffer = (buffer: ArrayBuffer) =>
  Array.from(new Uint8Array(buffer))
    .map(b => b.toString(16).padStart(2, '0'))
    .join('')

export function groupEventsByTime<T>(
  events: T[],
  getDate: (event: T) => Date,
): {
  label: string
  events: T[]
}[] {
  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  const thisWeekStart = new Date(today)
  thisWeekStart.setDate(thisWeekStart.getDate() - today.getDay())
  const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1)

  const groups: { [key: string]: T[] } = {}

  // Sort events in reverse chronological order
  const sortedEvents = [...events].sort(
    (a, b) => getDate(b).getTime() - getDate(a).getTime(),
  )

  sortedEvents.forEach(event => {
    const eventDate = getDate(event)
    const eventDay = new Date(
      eventDate.getFullYear(),
      eventDate.getMonth(),
      eventDate.getDate(),
    )

    if (eventDay.getTime() === today.getTime()) {
      if (!groups['Today']) groups['Today'] = []
      groups['Today'].push(event)
    } else if (eventDay.getTime() === yesterday.getTime()) {
      if (!groups['Yesterday']) groups['Yesterday'] = []
      groups['Yesterday'].push(event)
    } else if (eventDay >= thisWeekStart && eventDay < today) {
      if (!groups['This Week']) groups['This Week'] = []
      groups['This Week'].push(event)
    } else if (eventDay >= thisMonthStart && eventDay < thisWeekStart) {
      if (!groups['This Month']) groups['This Month'] = []
      groups['This Month'].push(event)
    } else {
      const monthYear = eventDate.toLocaleString('en-US', {
        month: 'long',
        year: 'numeric',
      })
      if (!groups[monthYear]) groups[monthYear] = []
      groups[monthYear].push(event)
    }
  })

  // Convert groups object to array and maintain order
  const orderedLabels = [
    'Today',
    'Yesterday',
    'This Week',
    'This Month',
    ...Object.keys(groups).filter(
      label =>
        !['Today', 'Yesterday', 'This Week', 'This Month'].includes(label),
    ),
  ]

  return orderedLabels
    .filter(label => groups[label]?.length > 0)
    .map(label => ({
      label,
      events: groups[label],
    }))
}

export enum ResultCode {
  InvalidCredentials = 'INVALID_CREDENTIALS',
  InvalidSubmission = 'INVALID_SUBMISSION',
  UserAlreadyExists = 'USER_ALREADY_EXISTS',
  UnknownError = 'UNKNOWN_ERROR',
  UserCreated = 'USER_CREATED',
  UserLoggedIn = 'USER_LOGGED_IN',
}

export const getMessageFromCode = (resultCode: string) => {
  switch (resultCode) {
    case ResultCode.InvalidCredentials:
      return 'Invalid credentials!'
    case ResultCode.InvalidSubmission:
      return 'Invalid submission, please try again!'
    case ResultCode.UserAlreadyExists:
      return 'User already exists, please log in!'
    case ResultCode.UserCreated:
      return 'User created, welcome!'
    case ResultCode.UnknownError:
      return 'Something went wrong, please try again!'
    case ResultCode.UserLoggedIn:
      return 'Logged in!'
  }
}

export function isMessageRole<Role extends SupabaseMessage['role']>(
  role: Role,
  message: SupabaseMessage,
): message is Extract<SupabaseMessage, { role: Role }> {
  return message.role === role
}

export function isObject(
  value: JSONValue,
): value is { [key: string]: JSONValue } {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

export function isObjectWithKey<Key extends string>(
  value: JSONValue,
  key: Key,
): value is { [key: string]: JSONValue } & { [key in Key]: JSONValue } {
  return isObject(value) && key in value
}

export function isString(value: JSONValue): value is string {
  return typeof value === 'string'
}

type MessageType = { created_at?: string; createdAt?: Date }
export function sortMessageFunc<T extends MessageType>(a: T, b: T) {
  const dateA = a.created_at ? new Date(a.created_at) : a.createdAt!
  const dateB = b.created_at ? new Date(b.created_at) : b.createdAt!
  return dateA.getTime() - dateB.getTime()
}

export function arrayEquals<T>(a: T[], b: T[], identify: (a: T) => string) {
  return (
    a.length === b.length &&
    a.every(a => b.some(b => identify(a) === identify(b)))
  )
}

let _url = ''

switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
  case 'production':
    _url = `https://${process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL}`
    break
  case 'preview':
    _url = `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
    break
  default:
    _url = 'http://localhost:3000'
}

export const url = _url

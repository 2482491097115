export type SupportedFileType = {
  type: string
  maxSize: number // in bytes
  description: string
}

export type ModelConfig = (
  | {
      provider: 'openai'
      model:
        | 'gpt-4o-2024-11-20'
        | 'gpt-4o-mini-2024-07-18'
        | 'o1-mini-2024-09-12'
    }
  | {
      provider: 'anthropic'
      model: 'claude-3-5-sonnet-latest' | 'claude-3-5-haiku-latest'
    }
  | {
      provider: 'deepseek'
      model: 'deepseek-chat' | 'deepseek-reasoner'
    }
) & {
  name: string
  supportedFiles?: SupportedFileType[]
}

export const MODELS = {
  'gpt-4o-mini': {
    provider: 'openai',
    name: 'GPT 4o Mini',
    model: 'gpt-4o-mini-2024-07-18',
    supportedFiles: [
      {
        type: 'text/*',
        maxSize: 5 * 1024 * 1024,
        description: 'Text files (TXT, MD, etc.)',
      },
      {
        type: 'image/*',
        maxSize: 5 * 1024 * 1024,
        description: 'Images (JPG, PNG, GIF, WebP)',
      },
    ],
  },
  'gpt-4o': {
    provider: 'openai',
    model: 'gpt-4o-2024-11-20',
    name: 'GPT 4o',
    supportedFiles: [
      {
        type: 'text/*',
        maxSize: 5 * 1024 * 1024,
        description: 'Text files (TXT, MD, etc.)',
      },
      {
        type: 'image/*',
        maxSize: 5 * 1024 * 1024,
        description: 'Images (JPG, PNG, GIF, WebP)',
      },
    ],
  },
  'o1-mini': {
    provider: 'openai',
    model: 'o1-mini-2024-09-12',
    name: 'O1 Mini',
    supportedFiles: [
      {
        type: 'text/*',
        maxSize: 5 * 1024 * 1024,
        description: 'Text files (TXT, MD, etc.)',
      },
    ],
  },
  haiku: {
    provider: 'anthropic',
    model: 'claude-3-5-haiku-latest',
    name: 'Claude 3.5 Haiku',
    supportedFiles: [
      {
        type: 'text/*',
        maxSize: 10 * 1024 * 1024,
        description: 'Text files (TXT, MD, etc.)',
      },
    ],
  },
  sonnet: {
    provider: 'anthropic',
    model: 'claude-3-5-sonnet-latest',
    name: 'Claude 3.5 Sonnet',
    supportedFiles: [
      {
        type: 'text/*',
        maxSize: 10 * 1024 * 1024,
        description: 'Text files (TXT, MD, etc.)',
      },
      {
        type: 'image/*',
        maxSize: 10 * 1024 * 1024,
        description: 'Images (JPG, PNG, GIF, WebP)',
      },
      {
        type: 'application/pdf',
        maxSize: 10 * 1024 * 1024,
        description: 'PDFs',
      },
    ],
  },
  'deepseek-v3': {
    provider: 'deepseek',
    model: 'deepseek-chat',
    name: 'DeepSeek V3',
    supportedFiles: [
      {
        type: 'text/*',
        maxSize: 10 * 1024 * 1024,
        description: 'Text files (TXT, MD, etc.)',
      },
    ],
  },
  'deepseek-r1': {
    provider: 'deepseek',
    model: 'deepseek-reasoner',
    name: 'DeepSeek R1',
    supportedFiles: [
      {
        type: 'text/*',
        maxSize: 10 * 1024 * 1024,
        description: 'Text files (TXT, MD, etc.)',
      },
    ],
  },
} as const satisfies Record<string, ModelConfig>

export type ModelName = keyof typeof MODELS

export const getLLMConfig = (modelName: ModelName): ModelConfig | undefined =>
  MODELS[modelName]

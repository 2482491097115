'use client';

import { useCallback, useEffect, useState } from 'react';
import { useIsMobile } from './use-is-mobile';
import { getCookie, setCookie, getCookies } from 'cookies-next';
export function useExpandableElement(key: string, initialValue: boolean) {
  const [isExpanded, setIsExpanded] = useState(initialValue);
  const isMobile = useIsMobile();
  const toggleIsExpanded = useCallback(() => {
    setIsExpanded(value => {
      const newState = !value;
      setCookie(key, JSON.stringify(newState), {
        path: '/',
        expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)
      });
      return newState;
    });
  }, [key]);
  useEffect(() => {
    if (!isMobile) {
      setIsExpanded(true);
    }
    if (isMobile && setIsExpanded) {
      setIsExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  // Handle opening and closing on screen size changes
  // Needs to be defined after the resize handler to overwrite it on load
  useEffect(() => {
    const cookieValue = getCookie(key);
    if (cookieValue === 'true' || cookieValue === undefined && window.innerWidth >= 768) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [key]);
  return {
    isExpanded,
    toggleIsExpanded,
    isMobile
  };
}
'use client';

import { fetchChats, SupabaseChats } from '@/app/(chat)/Sidebar/fetch-chats-browser';
import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/navigation';
import * as React from 'react';
import { PropsWithChildren, useCallback, useState } from 'react';
import { toast } from 'sonner';
import { useImmer } from 'use-immer';
import { createBrowserSupabase } from '../supabase/browser';
import { fetchUser } from '../supabase/queries';
import { useExpandableElement } from './use-expandable-element';
const LOCAL_STORAGE_KEY = 'sidebar';
interface SidebarContext {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  isLoading: boolean;
  isMobile: boolean;
  chats: SupabaseChats;
  setInitialChats: (chats: SupabaseChats) => void;
  refreshChats: () => void;
  deleteChat: (chatId: string) => Promise<void>;
}
const SidebarContext = React.createContext<SidebarContext | undefined>(undefined);
export function useSidebar() {
  const context = React.useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebarContext must be used within a SidebarProvider');
  }
  return context;
}
export function SidebarProvider({
  children,
  initialValue
}: PropsWithChildren<{
  initialValue: boolean;
}>) {
  const [isLoading, setLoading] = useState(true);
  const [chats, setChats] = useImmer<SupabaseChats>([]);
  const router = useRouter();
  const {
    isExpanded: isSidebarOpen,
    toggleIsExpanded: toggleSidebar,
    isMobile
  } = useExpandableElement(LOCAL_STORAGE_KEY, initialValue);
  const setInitialChats: SidebarContext['setInitialChats'] = useCallback((chats: SupabaseChats) => {
    setChats(chats);
    setLoading(false);
  }, [setChats]);
  const refreshChats = useCallback(async () => {
    await fetchChats((await fetchUser(createBrowserSupabase()))?.id).then(res => {
      if (res.error) {
        console.error(res.error);
        captureException(res.error);
        toast.error('There was an error fetching your chats');
        return;
      }
      setChats(res.data);
    });
  }, [setChats]);
  const deleteChat: SidebarContext['deleteChat'] = useCallback(async (chatId: string) => {
    // Store the current state for rollback
    const originalChats = chats;

    // Optimistically remove the chat from the UI
    setChats(draft => {
      const newChats = draft.filter(chat => chat.id !== chatId);
      return newChats;
    });
    router.prefetch('/chat');
    try {
      // Delete chat and its messages from the database
      const r = await createBrowserSupabase().rpc('delete_chat', {
        chat_id: chatId
      });
      if (r.error) {
        throw r.error;
      }
      toast.success('Chat deleted');
      router.push('/chat');
    } catch (error) {
      // Rollback on error
      setChats(originalChats);
      toast.error('There was an error deleting the chat');
      captureException(error);
    }
  }, [chats, setChats, router]);
  return <SidebarContext.Provider value={{
    isSidebarOpen,
    toggleSidebar,
    isLoading,
    isMobile,
    chats,
    setInitialChats,
    refreshChats,
    deleteChat
  }} data-sentry-element="unknown" data-sentry-component="SidebarProvider" data-sentry-source-file="use-sidebar.tsx">
      {children}
    </SidebarContext.Provider>;
}
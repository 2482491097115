import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Info, LoaderCircle, MessageCircle } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useState, useTransition } from 'react';
import { toast } from 'sonner';
import { createStartingBonusTransaction } from '../actions';
import { OnboardingContentProps } from '../config';
export function BillingAccounts({
  user,
  moveToNextStep
}: OnboardingContentProps) {
  const [isSubmitting, startSubmition] = useTransition();
  const [selectedBillingAccount, setSelectedBillingAccount] = useState<string>(user.user_billing_accounts.find(uba => uba.is_admin)?.billing_account_id ?? user.user_billing_accounts[0].billing_account_id);
  const router = useRouter();
  return <>
      <p className="text-sm text-muted-foreground">
        Which billing account would you like to add the free credits to?
      </p>
      <div className="flex flex-col gap-1">
        {user.user_billing_accounts.map((billingAccount, index) => <button key={index} className={cn('flex w-full items-center justify-between rounded-2xl border border-muted-foreground p-2 px-3 text-start shadow', billingAccount.billing_account_id === selectedBillingAccount ? 'border-green-500' : 'border-dashed opacity-50')} onClick={() => setSelectedBillingAccount(billingAccount.billing_account_id)}>
            <div>
              <h4 className="font-medium">
                {billingAccount.billing_account?.name}
              </h4>
              <p className="text-xs text-muted-foreground">Default Account</p>
            </div>
            {billingAccount.billing_account_id === selectedBillingAccount && <p className="rounded-lg bg-green-500 px-2 py-1 font-mono font-semibold text-white">
                + $0.50
              </p>}
          </button>)}
      </div>
      <p className="text-muted-foreground">
        <Info className="me-2 inline size-3.5 shrink-0" data-sentry-element="Info" data-sentry-source-file="billing-accounts.tsx" />
        You can manage your billing accounts in your account settings.
      </p>
      <Button disabled={isSubmitting} className="mt-auto rounded-xl text-lg" onClick={() => startSubmition(async () => {
      const error = await createStartingBonusTransaction(user.id, selectedBillingAccount);
      if (error) {
        toast.error('Failed to create bonus transaction');
        return;
      }
      router.refresh();
      moveToNextStep();
    })} data-sentry-element="Button" data-sentry-source-file="billing-accounts.tsx">
        Start chatting!
        {isSubmitting ? <LoaderCircle strokeWidth={1} className="size-4 shrink-0 animate-spin" /> : <MessageCircle className="size-4 shrink-0" />}
      </Button>
    </>;
}
import { useCookieState } from '@/lib/hooks/use-cookie-state';
import { getLLMConfig, ModelName, MODELS } from '@/lib/llm-registry';
import { SupabaseModel } from '@/lib/supabase/queries';
import { cn, formatDollars } from '@/lib/utils';
import { Accordion } from '@radix-ui/react-accordion';
import { AlertTriangle, ArrowDownToDot, ArrowUpFromDot, ArrowUpRightFromSquare, Bot, Brain, ChevronsUpDown, FileImage, FileText, FileType, Lightbulb } from 'lucide-react';
import Image from 'next/image';
import { forwardRef, useMemo, useState } from 'react';
import { toast } from 'sonner';
import { FairLogoText } from '../fair-logo';
import { ShortcutIcon } from '../shortcut-icon';
import { AccordionContent, AccordionItem, AccordionTrigger } from '../ui/accordion';
import { Button, ButtonProps } from '../ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../ui/dialog';
import { Switch } from '../ui/switch';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
function LearnMoreAboutTokens() {
  return <Accordion type="multiple" data-sentry-element="Accordion" data-sentry-component="LearnMoreAboutTokens" data-sentry-source-file="model-picker.tsx">
      <AccordionItem value="1" className="border-none" data-sentry-element="AccordionItem" data-sentry-source-file="model-picker.tsx">
        <AccordionTrigger className="flex items-center gap-1 py-1" data-sentry-element="AccordionTrigger" data-sentry-source-file="model-picker.tsx">
          Learn more about tokens
        </AccordionTrigger>
        <AccordionContent className="rounded-md border px-2 py-1 text-sm text-muted-foreground" data-sentry-element="AccordionContent" data-sentry-source-file="model-picker.tsx">
          <p className="pb-1 text-foreground">What are tokens?</p>
          <p>
            LLMs work on a per-token basis. Every time you send a message, the
            chat history is sent to an LLM of your choice, and it computes the
            response.
          </p>
          <p className="pb-1 pt-2 text-foreground">How do we count tokens?</p>
          <p>
            In <FairLogoText data-sentry-element="FairLogoText" data-sentry-source-file="model-picker.tsx" />, you only pay for what you use; the more tokens
            you send or recieve, the more credits will be deducted from your
            account.
          </p>
          <p className="pb-1 pt-2 text-foreground">
            How many tokens are there in a message?
          </p>
          <p>
            1 000 000 tokens is approximately 750 000 words. For example, the 7
            books of Harry Potter are around 1,084,170 words.
          </p>
        </AccordionContent>
      </AccordionItem>
    </Accordion>;
}
function Pricing({
  model
}: {
  model: SupabaseModel;
}) {
  return model.pricing && model.pricing.per_input_token + model.pricing.per_output_token + model.pricing.per_message + model.pricing.per_month > 0 ? <>
      <TooltipProvider delayDuration={0} data-sentry-element="TooltipProvider" data-sentry-source-file="model-picker.tsx">
        <Tooltip data-sentry-element="Tooltip" data-sentry-source-file="model-picker.tsx">
          <TooltipTrigger data-sentry-element="TooltipTrigger" data-sentry-source-file="model-picker.tsx">
            {model.pricing.per_input_token > 0 && <div className="relative flex items-center gap-1 overflow-hidden rounded-full bg-muted px-2 py-0.5 pe-2.5 font-mono text-xs">
                <ArrowUpFromDot className="size-3.5 shrink-0" />
                {(model.pricing.per_input_token * 1000000).toFixed(2)} $/M
              </div>}
          </TooltipTrigger>
          <TooltipContent side="bottom" align="center" className="max-w-60" data-sentry-element="TooltipContent" data-sentry-source-file="model-picker.tsx">
            Price per 1 million tokens sent to the model (that&apos;s around 750
            thousand words)
          </TooltipContent>
        </Tooltip>
        <Tooltip data-sentry-element="Tooltip" data-sentry-source-file="model-picker.tsx">
          <TooltipTrigger data-sentry-element="TooltipTrigger" data-sentry-source-file="model-picker.tsx">
            {model.pricing.per_output_token > 0 && <div className="relative flex items-center gap-1 rounded-full bg-muted px-2 py-0.5 pe-2.5 font-mono text-xs">
                <div className="flex items-center">
                  <ArrowDownToDot className="size-3.5 shrink-0" />
                  {model.is_reasoning && <>
                      <span className="-ms-0.5 text-muted-foreground">/</span>
                      <Brain className="me-0.5 size-3.5 shrink-0 text-fuchsia-500" />
                    </>}
                </div>
                {(model.pricing.per_output_token * 1000000).toFixed(2)} $/M
              </div>}
          </TooltipTrigger>
          <TooltipContent side="bottom" align="center" className="max-w-60" data-sentry-element="TooltipContent" data-sentry-source-file="model-picker.tsx">
            Price per 1 million tokens generated by the model{' '}
            {model.is_reasoning ? <>
                (includes the internal reasoning tokens, which can be as much as
                10x more)
              </> : <>(that&apos;s around 750 thousand words)</>}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      {model.pricing.per_message > 0 && <li>
          <span className="font-mono">
            {formatDollars(model.pricing.per_message, {
          decimals: 4,
          fixed: true
        })}{' '}
          </span>
          per message
        </li>}
      {model.pricing.per_month > 0 && <li>
          <span className="font-mono">
            {formatDollars(model.pricing.per_month, {
          fixed: true
        })}
          </span>
          per month
        </li>}
    </> : <span className="rounded-full bg-green-500 px-2 py-0.5 text-xs text-white" data-sentry-component="Pricing" data-sentry-source-file="model-picker.tsx">
      FREE
    </span>;
}
function FileSupport({
  model
}: {
  model: SupabaseModel;
}) {
  const modelConfig = getLLMConfig(model.id_tag as ModelName);
  const supportedFiles = modelConfig?.supportedFiles || [];
  if (!supportedFiles?.length) return null;
  return <div className="flex flex-wrap gap-1" data-sentry-component="FileSupport" data-sentry-source-file="model-picker.tsx">
      {supportedFiles.map((type, i) => <div key={i} className="flex items-center gap-1 rounded-full bg-muted px-2 py-0.5 text-xs text-muted-foreground">
          {type.type === 'image/*' && <FileImage className="size-3" />}
          {type.type === 'text/*' && <FileText className="size-3" />}
          {type.type === 'application/pdf' && <FileType className="size-3" />}
          {type.description.split('(')[0].trim()}
        </div>)}
    </div>;
}
function ProTip() {
  return <p className="hidden items-center justify-center gap-2 text-xs text-muted-foreground sm:flex" data-sentry-component="ProTip" data-sentry-source-file="model-picker.tsx">
      <Lightbulb className="size-4 shrink-0" data-sentry-element="Lightbulb" data-sentry-source-file="model-picker.tsx" />
      <span className="font-bold">Pro Tip:</span>
      Use the shortcut <ShortcutIcon cmd data-sentry-element="ShortcutIcon" data-sentry-source-file="model-picker.tsx">M</ShortcutIcon>
      to open the model picker.
    </p>;
}
type ModelPickerProps = {
  model?: SupabaseModel | undefined;
  setModel: (model: SupabaseModel) => void;
  availableModels: SupabaseModel[];
  className?: string;
  required?: boolean;
  title?: string;
  variant?: ButtonProps['variant'];
  modelFileSupport?: Record<string, boolean>;
};
export const ModelPicker = forwardRef<HTMLButtonElement, ModelPickerProps>(({
  model,
  setModel,
  availableModels,
  className,
  required,
  title = 'Choose a model',
  variant = 'outline',
  modelFileSupport
}, ref) => {
  const [open, setOpen] = useState(false);
  const [isCompactView, setIsCompactView] = useCookieState(false, 'compact-model-picker-view');
  const groupedModels = useMemo(() => Object.entries(MODELS).map(([m]) => availableModels.find(a => a.id_tag === m)).filter(a => !!a).reduce((acc, m) => {
    const index = acc.findIndex(a => a[0].provider === m.provider);
    if (index !== -1) {
      acc[index].push(m);
    } else {
      acc.push([m]);
    }
    return acc;
  }, [] as SupabaseModel[][]), [availableModels]);
  return <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button ref={ref} variant={variant} role="combobox" aria-expanded={open} className={cn('transition-all', className)}>
            {model && availableModels.find(m => m.id === model.id) ? <>
                {model.icon && <Image src={model.icon} width={20} height={20} alt={model.name} className="shrink-0 overflow-hidden rounded-full" />}
                {model.name}
                {modelFileSupport && !modelFileSupport[model.id] && <AlertTriangle className="size-4 text-amber-500" />}
              </> : <>
                <AlertTriangle className="size-4 text-amber-500" />
                No LLM
              </>}
            <ChevronsUpDown className="ms-auto size-4 shrink-0 opacity-50" />
          </Button>
        </DialogTrigger>
        <DialogContent
    // We want to focus on the text area, so disabling the default behavior
    onCloseAutoFocus={e => e.preventDefault()} className="max-w-3xl">
          <DialogHeader>
            <DialogTitle className="text-2xl">{title}</DialogTitle>
          </DialogHeader>
          <LearnMoreAboutTokens />
          <div className="flex items-center justify-start gap-2 text-xs text-muted-foreground">
            <label htmlFor="compact-view" className="cursor-pointer">
              Compact view
            </label>
            <Switch id="compact-view" checked={isCompactView} onCheckedChange={setIsCompactView} />
          </div>
          {groupedModels.map(models => <div key={models[0].provider} className="mb-2 flex flex-col gap-2">
              <h3 className="text-lg font-semibold">{models[0].provider}</h3>
              <div className={cn('flex flex-col gap-4 *:flex-1', isCompactView && 'gap-2')}>
                {models.map(m => <button key={m.id} onClick={() => {
            const newModel = availableModels.find(m2 => m2.id_tag === m.id_tag);
            if (!newModel) {
              toast.error('Model not found');
              return;
            }
            setModel(newModel);
            setOpen(false);
          }} disabled={required && m.id_tag === model?.id_tag} className={cn('relative flex items-start gap-4 rounded-lg border p-3 pt-4 text-start shadow transition-all focus-visible:outline-offset-4 focus-visible:outline-fuchsia-500/70 hover:enabled:shadow-lg', m.id_tag === model?.id_tag && 'ring-2 ring-foreground', modelFileSupport && !modelFileSupport[m.id] && 'border-amber-500/50 bg-amber-500/5', isCompactView && 'items-center py-2')}>
                    {!isCompactView && <div className="absolute right-3 top-0 flex -translate-y-1/2 flex-wrap gap-1">
                        {m.tags.map(t => <span key={t} className={cn('rounded-full border bg-background px-2.5 py-1 text-xs font-semibold', t === 'NEW' && 'border-foreground bg-foreground text-background')}>
                            {t}
                          </span>)}
                      </div>}
                    {m.icon ? <Image src={m.icon} width={32} height={32} alt={m.name} className="overflow-hidden rounded-full" /> : <div className="grid size-8 place-content-center rounded-full border-2">
                        <Bot className="m-auto size-4 text-muted-foreground" />
                      </div>}
                    <div className="flex flex-1 flex-col gap-1">
                      <div className="flex items-center gap-2">
                        <h4 className="font-semibold">{m.name}</h4>
                        {m.link && <a href={m.link} className="ms-auto flex items-center gap-1 text-sm text-blue-500" target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()} tabIndex={-1}>
                            Learn more
                            <ArrowUpRightFromSquare className="size-3.5 shrink-0" />
                          </a>}
                      </div>
                      {!isCompactView && <p className="text-sm text-muted-foreground">
                          {m.description}
                        </p>}
                      {isCompactView ? <>
                          <div className="flex flex-wrap gap-2 pt-1">
                            <Pricing model={m} />
                            <FileSupport model={m} />
                            {modelFileSupport && !modelFileSupport[m.id] && <div className="flex items-center gap-1">
                                <AlertTriangle className="size-4 text-amber-500" />
                                <span className="text-xs text-amber-500">
                                  Unsupported files used in conversation
                                </span>
                              </div>}
                          </div>
                        </> : <div className="grid grid-cols-2 gap-2 pt-1">
                          <div>
                            <p className="pb-1 text-xs font-semibold">
                              Pricing
                            </p>
                            <div className="flex flex-wrap gap-2">
                              <Pricing model={m} />
                            </div>
                          </div>
                          <div>
                            <p className="pb-1 text-xs font-semibold">
                              Features
                            </p>
                            <div className="flex flex-wrap gap-2">
                              <FileSupport model={m} />
                              {m.context_window && <span className="rounded-full bg-muted px-2 py-0.5 text-xs text-muted-foreground">
                                  {m.context_window / 1000}k context
                                </span>}
                              {modelFileSupport && !modelFileSupport[m.id] && <div className="flex items-center gap-1">
                                  <AlertTriangle className="size-4 text-amber-500" />
                                  <span className="text-xs text-amber-500">
                                    Unsupported files used in conversation
                                  </span>
                                </div>}
                            </div>
                          </div>
                        </div>}
                    </div>
                  </button>)}
              </div>
            </div>)}
          <ProTip />
        </DialogContent>
      </Dialog>;
});
ModelPicker.displayName = 'ModelPicker';
'use client';

import { PopoverContentProps, PopoverProps, PopoverTriggerProps } from '@radix-ui/react-popover';
import { TooltipContentProps, TooltipProps, TooltipTriggerProps } from '@radix-ui/react-tooltip';
import { createContext, forwardRef, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip';
const TouchContext = createContext<boolean | undefined>(undefined);
const useTouch = () => useContext(TouchContext);
export const TouchProvider = (props: PropsWithChildren) => {
  const [isTouch, setTouch] = useState<boolean>();
  useEffect(() => {
    setTouch(window.matchMedia('(pointer: coarse)').matches);
  }, []);
  return <TouchContext.Provider value={isTouch} {...props} data-sentry-element="unknown" data-sentry-component="TouchProvider" data-sentry-source-file="hybrid-tooltip.tsx" />;
};
export const HybridTooltip = (props: TooltipProps & PopoverProps) => {
  const isTouch = useTouch();
  return isTouch ? <Popover {...props} data-sentry-element="Popover" data-sentry-component="HybridTooltip" data-sentry-source-file="hybrid-tooltip.tsx" /> : <Tooltip {...props} data-sentry-element="Tooltip" data-sentry-component="HybridTooltip" data-sentry-source-file="hybrid-tooltip.tsx" />;
};
export const HybridTooltipTrigger = forwardRef<HTMLButtonElement, TooltipTriggerProps & PopoverTriggerProps>((props, ref) => {
  const isTouch = useTouch();
  return isTouch ? <PopoverTrigger {...props} ref={ref} /> : <TooltipTrigger {...props} ref={ref} />;
});
HybridTooltipTrigger.displayName = 'HybridTooltipTrigger';
export const HybridTooltipContent = forwardRef<HTMLDivElement, TooltipContentProps & PopoverContentProps>((props, ref) => {
  const isTouch = useTouch();
  return isTouch ? <PopoverContent {...props} ref={ref} /> : <TooltipContent {...props} ref={ref} />;
});
HybridTooltipContent.displayName = 'HybridTooltipContent';
'use client';

import { getCookie, setCookie } from 'cookies-next';
import { useCallback, useEffect, useState } from 'react';
export function useCookieState<T extends string | boolean>(initialValue: T, key: string) {
  const [value, _setValue] = useState<T>(initialValue);
  useEffect(() => {
    const cookieValue = getCookie(key);
    if (cookieValue) {
      if (typeof initialValue === 'boolean') {
        _setValue((cookieValue === 'true') as T);
      } else {
        _setValue(cookieValue as T);
      }
    }
  }, [key, initialValue]);
  const setValue = useCallback((value: T) => {
    _setValue(value);
    setCookie(key, value.toString(), {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365)
    });
  }, [key]);
  return [value, setValue] as const;
}
import { SupabaseClient } from '@supabase/supabase-js';
import { Attachment } from 'ai';
import { lookup } from 'mime-types';
import { Database } from './database.types';
type AttachmentResult = {
  path: string;
  signedUrl: string;
};
export async function fetchChat(supabase: SupabaseClient<Database>, {
  id,
  snapshot_of
}: {
  id: string;
  snapshot_of?: string;
}) {
  let q = supabase.from('chats').select(`*, 
      messages(
        *,
        user:users(*),
        llm_usage:llm_usages(
          *,
          llm:llms(
            *,
            pricing:llm_pricings(*)
          ),
          pricing:llm_pricings(*)
        )
      ), 
      chat_participants(
        user_id,
        user:users()
      ),
      snapshots:chats(
        id,
        created_at,
        messages(
          content,
          role,
          llm_usage_id,
          attachments
        ),
        invites(
         recipient_email,
         code
        )
      ),
      invites(
        id,
        recipient_email,
        code
      )
      `).eq('id', id).is('messages.is_removed', false).is('snapshots.messages.is_removed', false);
  if (snapshot_of) {
    q = q.eq('snapshot_of', snapshot_of);
  }
  let res = await q.order('created_at', {
    referencedTable: 'messages',
    ascending: true
  }).order('created_at', {
    referencedTable: 'chats',
    ascending: false
  }).maybeSingle();
  if (res.error) {
    return res;
  }
  const attachmentPaths = res.data?.messages.flatMap(m => m.attachments ?? []).filter((at, i, arr) => arr.indexOf(at) === i);
  if (!attachmentPaths?.length) {
    return {
      ...res,
      data: res.data ? {
        ...res.data,
        snapshots: res.data!.snapshots as unknown as Array<NonNullable<typeof res.data.snapshots>>,
        messages: res.data.messages.map(m => ({
          ...m,
          attachments: []
        }))
      } : res.data
    };
  }
  const processAttachment = async (path: string): Promise<AttachmentResult | null> => {
    const filename = path.split('/').pop() ?? 'Unnamed file';
    const contentType = lookup(filename) || 'application/octet-stream';
    if (contentType.startsWith('text/')) {
      const {
        data,
        error
      } = await supabase.storage.from('attachments').download(path);
      if (error || !data) {
        throw error || new Error('Failed to download file');
      }
      const base64 = Buffer.from(await data.arrayBuffer()).toString('base64');
      return {
        path,
        signedUrl: `data:${contentType};base64,${base64}`
      };
    }
    const {
      data,
      error
    } = await supabase.storage.from('attachments').createSignedUrl(path, 60 * 60 * 24);
    if (error) {
      return null;
    }
    return {
      path,
      signedUrl: data.signedUrl
    };
  };
  const attachmentsResult = await Promise.all(attachmentPaths.map(path => processAttachment(path)));
  return {
    ...res,
    data: res.data ? {
      ...res.data,
      snapshots: res.data!.snapshots as unknown as Array<NonNullable<typeof res.data.snapshots>>,
      messages: res.data.messages.map(m => ({
        ...m,
        attachments: m.attachments?.map(a => {
          const filename = a.split('/').pop() ?? 'Unnamed file';
          const contentType = lookup(filename) || 'application/octet-stream';
          const attachment = attachmentsResult.find(at => at?.path === a);
          if (!attachment) {
            return {
              contentType: 'text/plain',
              name: 'Unknown file',
              url: `data:text/plain;base64,${Buffer.from('File not found').toString('base64')}`
            } as Attachment;
          }
          return {
            contentType,
            name: a,
            url: attachment.signedUrl
          } as Attachment;
        }).filter(Boolean)
      }))
    } : res.data
  };
}
export type SupabaseChat = NonNullable<Awaited<ReturnType<typeof fetchChat>>['data']>;
export type SupabaseMessage = SupabaseChat['messages'][number];
export async function fetchModels(supabase: SupabaseClient<Database>) {
  const res = await supabase.from('llms').select(`*, 
      pricing:llm_pricings (
        *
      )
      `).is('is_active', true);
  return res;
}
export type SupabaseModel = NonNullable<Awaited<ReturnType<typeof fetchModels>>['data']>[number];
export const supabaseUserQuery = `
  *,
  default_llm:llms(
    *,
    pricing:llm_pricings(*)
  ),
  user_billing_accounts(
    *,
    billing_account:billing_accounts(*)
  )`;
export async function fetchUser(supabase: SupabaseClient<Database>) {
  const {
    data: {
      user: authUser
    }
  } = await supabase.auth.getUser();
  if (!authUser) {
    return null;
  }
  const {
    data: user,
    error: profileError
  } = await supabase.from('users').select(supabaseUserQuery).eq('id', authUser?.id).single();
  if (profileError) {
    throw profileError;
  }
  return {
    ...authUser,
    ...user
  };
}
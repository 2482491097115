'use client';

import { Link } from '@/components/Link';
import { Button } from '@/components/ui/button';
import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  useEffect(() => {
    console.error(error);
    Sentry.captureException(error);
  }, [error]);
  return <div className="flex min-h-screen flex-col items-center justify-center bg-background p-4" data-sentry-component="GlobalError" data-sentry-source-file="error.tsx">
      <main className="text-center">
        <h2 className="mb-4 text-2xl font-semibold text-foreground">
          Oops... Something went wrong
        </h2>
        <p className="mb-8 text-muted-foreground">
          There seems to be an error on our side. We are working on a fix!
        </p>
        <div className="space-x-4">
          <Button asChild data-sentry-element="Button" data-sentry-source-file="error.tsx">
            <Link href="/" data-sentry-element="Link" data-sentry-source-file="error.tsx">Go Home</Link>
          </Button>
          <Button variant="outline" onClick={() => window.history.back()} data-sentry-element="Button" data-sentry-source-file="error.tsx">
            Go Back
          </Button>
        </div>
      </main>
    </div>;
}
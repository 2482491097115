import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '@/components/ui/alert-dialog';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from '@/lib/utils';
const buttonVariants = cva('inline-flex items-center gap-2 justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50', {
  variants: {
    variant: {
      default: 'bg-primary text-primary-foreground shadow hover:enabled:bg-primary/90',
      outline: 'border border-input bg-background shadow-sm hover:enabled:bg-muted hover:enabled:text-muted-foreground',
      secondary: 'bg-secondary text-secondary-foreground shadow-sm hover:enabled:bg-secondary/80',
      ghost: 'hover:enabled:bg-foreground/15',
      subtle: 'bg-muted-foreground/10 hover:enabled:bg-muted-foreground/20',
      link: 'text-primary underline-offset-4 hover:enabled:underline',
      destructive: 'bg-destructive text-destructive-foreground shadow-sm hover:enabled:bg-destructive/90',
      'destructive-outline': 'border border-destructive text-red-600 bg-transparent shadow-sm hover:enabled:bg-destructive hover:enabled:text-destructive-foreground',
      'destructive-ghost': 'text-destructive hover:enabled:bg-destructive/20',
      'destructive-subtle': 'bg-destructive/20 text-destructive hover:enabled:bg-destructive/20',
      success: 'bg-green-500 text-destructive-foreground shadow-sm hover:enabled:bg-green-600'
    },
    size: {
      default: 'px-4 py-2',
      sm: 'px-3 py-1.5 text-xs',
      xs: 'py-1 px-2 text-xs',
      lg: 'px-8',
      icon: 'p-2'
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default'
  }
});
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  variant,
  size,
  asChild = false,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : 'button';
  return <Comp className={cn(buttonVariants({
    variant,
    size,
    className
  }))} ref={ref} {...props} />;
});
Button.displayName = 'Button';
const ConfirmButton = ({
  children,
  title,
  description,
  onConfirm,
  confirmLabel = 'Potvrdiť',
  onCancel,
  cancelLabel = 'Zrušiť',
  variant
}: React.PropsWithChildren<{
  title?: React.ReactNode;
  description?: React.ReactNode;
  onConfirm?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  confirmLabel?: React.ReactNode;
  onCancel?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  cancelLabel?: React.ReactNode;
} & Pick<VariantProps<typeof buttonVariants>, 'variant'>>) => <AlertDialog data-sentry-element="AlertDialog" data-sentry-component="ConfirmButton" data-sentry-source-file="button.tsx">
    <AlertDialogTrigger asChild data-sentry-element="AlertDialogTrigger" data-sentry-source-file="button.tsx">{children}</AlertDialogTrigger>
    <AlertDialogContent data-sentry-element="AlertDialogContent" data-sentry-source-file="button.tsx">
      <AlertDialogHeader data-sentry-element="AlertDialogHeader" data-sentry-source-file="button.tsx">
        <AlertDialogTitle data-sentry-element="AlertDialogTitle" data-sentry-source-file="button.tsx">{title}</AlertDialogTitle>
        <AlertDialogDescription data-sentry-element="AlertDialogDescription" data-sentry-source-file="button.tsx">{description}</AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter data-sentry-element="AlertDialogFooter" data-sentry-source-file="button.tsx">
        <AlertDialogCancel onClick={onCancel} data-sentry-element="AlertDialogCancel" data-sentry-source-file="button.tsx">{cancelLabel}</AlertDialogCancel>
        <AlertDialogAction variant={variant} onClick={onConfirm} data-sentry-element="AlertDialogAction" data-sentry-source-file="button.tsx">
          {confirmLabel}
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>;
export { Button, buttonVariants, ConfirmButton };
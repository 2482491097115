'use client';

import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import * as Sentry from '@sentry/nextjs';
import { GeistMono } from 'geist/font/mono';
import { GeistSans } from 'geist/font/sans';
// Cannot use custom Link, because glboal-error doesn't have context providers
import Link from 'next/link';
import { useEffect } from 'react';
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  useEffect(() => {
    console.error(error);
    Sentry.captureException(error);
  }, [error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body className={cn('flex h-screen w-screen flex-col overflow-hidden bg-background font-sans antialiased', GeistSans.variable, GeistMono.variable)}>
        <div className="flex min-h-screen flex-col items-center justify-center bg-background p-4">
          <main className="text-center">
            <h2 className="mb-4 text-2xl font-semibold text-foreground">
              Oops... Something went wrong
            </h2>
            <p className="mb-8 text-muted-foreground">
              There seems to be an error on our side.
            </p>
            <div className="space-x-4">
              <Button asChild data-sentry-element="Button" data-sentry-source-file="global-error.tsx">
                <Link href="/" data-sentry-element="Link" data-sentry-source-file="global-error.tsx">Go Home</Link>
              </Button>
              <Button variant="outline" onClick={() => window.history.back()} data-sentry-element="Button" data-sentry-source-file="global-error.tsx">
                Go Back
              </Button>
            </div>
          </main>
        </div>
      </body>
    </html>;
}
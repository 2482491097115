import { UserHookState } from '@/lib/supabase/browser';
import { FairLogoText } from '../fair-logo';
import { LetsSetUpYourAccount } from './steps/account-setup';
import { BillingAccounts } from './steps/billing-accounts';
export type OnboardingContentProps = {
  user: NonNullable<UserHookState['data']>;
  updateUser: UserHookState['update'];
  moveToNextStep: (save?: boolean) => void;
};
export const ONBOARDING_STEPS: {
  id: string;
  title: React.ReactNode;
  description?: React.ReactNode;
  content?: React.FC<OnboardingContentProps>;
  buttonContent?: React.ReactNode;
}[] = [{
  id: 'welcome_and_setup_2.0',
  title: <>
        Welcome to <FairLogoText />!
      </>,
  description: <>
        The cheapest way to use state-of-the art AI models. Let&apos;s get your
        account set up.
      </>,
  content: LetsSetUpYourAccount
}, {
  id: 'billing_and_credits_2.0',
  title: 'Your billing account & free credits',
  description: <>
        When using <FairLogoText className="inline-block" />, you&apos;ll spend
        credits that are associated with a billing account. To get you started,
        we are giving you{' '}
        <span className="rounded-lg bg-green-500 px-2 py-1 font-mono font-bold text-white">
          $0.50
        </span>{' '}
        in{' '}
        <span className="font-semibold italic text-green-500">
          free credits
        </span>
        .
      </>,
  content: BillingAccounts
}] as const;